@import '@angular/cdk/overlay-prebuilt.css';
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Fira+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");
/* Basic site-wide styling and main elements */
[hidden] {
  display: none !important;
}

html {
  margin: 0;
}

body {
  font-family: "Be Vietnam Pro", sans-serif;
  margin: 0;
}

div {
  box-sizing: border-box;
  color: var(--text-color);
  font-family: "Be Vietnam Pro", sans-serif;
}

hr {
  border: 1px solid var(--background-secondary-color);
  border-radius: 1px;
  margin-bottom: 24px;
  width: 100%;
}

h1 {
  color: var(--text-color);
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 44px;
  font-weight: 700;
  margin: 0 0 24px 0;
}

h2 {
  color: var(--text-color);
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 36px;
  font-weight: 700;
  margin: 0 0 24px 0;
}

h3 {
  color: var(--text-color);
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 28px;
  font-weight: 700;
  margin: 0 0 12px 0;
}

h4 {
  color: var(--text-color);
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 4px 0;
}

h5 {
  color: var(--text-color);
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 20px;
  font-weight: 300;
  margin: 0 0 4px 0;
}

h6 {
  color: var(--text-color);
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 8px 0;
}

p {
  box-sizing: border-box;
  color: var(--text-light-color);
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  margin: 0 0 8px 0;
  max-width: 700px;
}

p.bold {
  font-weight: 700;
}

p.caption,
p.meta {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 4px;
}

p.pink {
  color: #F84B7F !important;
}

strong,
strong > p {
  font-weight: 700 !important;
}

h1.light,
h2.light,
h3.light,
h4.light,
h5.light,
h6.light,
p.light {
  color: var(--text-secondary-color);
}

h1.super-light,
h2.super-light,
h3.super-light,
h4.super-light,
h5.super-light,
h6.super-light,
p.super-light {
  opacity: 0.59;
}

h1.disabled,
h2.disabled,
h3.disabled,
h4.disabled,
h5.disabled,
h6.disabled,
p.disabled {
  color: var(--text-disabled-color);
}

h1.white,
h2.white,
h3.white,
h4.white,
h5.white,
h6.white,
p.white {
  color: #ffffff;
}

h1.white.light,
h2.white.light,
h3.white.light,
h4.white.light,
h5.white.light,
h6.white.light,
p.white.light {
  opacity: 0.95;
}

h1.white.super-light,
h2.white.super-light,
h3.white.super-light,
h4.white.super-light,
h5.white.super-light,
h6.white.super-light,
p.white.super-light {
  opacity: 0.79;
}

h1.speakable,
h2.speakable,
h3.speakable,
h4.speakable,
h5.speakable,
h6.speakable,
p.speakable {
  transition-duration: 0.3s;
}
h1.speakable:hover,
h2.speakable:hover,
h3.speakable:hover,
h4.speakable:hover,
h5.speakable:hover,
h6.speakable:hover,
p.speakable:hover {
  color: var(--info-blue) !important;
  cursor: pointer;
}
h1.speakable:hover *,
h2.speakable:hover *,
h3.speakable:hover *,
h4.speakable:hover *,
h5.speakable:hover *,
h6.speakable:hover *,
p.speakable:hover * {
  color: var(--info-blue) !important;
  transition-duration: 0.3s;
}

button {
  background-color: var(--primary-color);
  border: none;
  border-radius: 10px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  padding: 14px 64px;
  transition-duration: 0.3s;
  width: fit-content;
}

button:hover {
  background-color: var(--primary-color);
  cursor: pointer;
  transform: translateY(-4px);
}

button.secondary {
  background-color: var(--background-color);
  border: 2px solid var(--background-tertiary-color);
  border-radius: 10px;
  color: var(--text-disabled-color);
  font-size: 16px;
  font-weight: 400;
  outline: none;
  padding: 12px 38px;
  width: fit-content;
}

button.secondary:hover {
  border-color: var(--background-tertiary-color);
  color: var(--text-disabled-color);
  cursor: pointer;
}

button.glass {
  background: rgba(255, 255, 255, 0.07);
  border: none;
  color: rgba(255, 255, 255, 0.59);
}

button.disabled {
  background-color: var(--background-secondary-color);
}
button.disabled:hover {
  cursor: initial;
  transform: none;
}

a {
  color: #F84B7F;
  text-decoration: none;
  transition-duration: 0.3s;
}

a > p {
  transition-duration: 0.3s;
}

a:hover {
  color: #F84B7F;
  cursor: pointer;
  transform: translateY(-2px);
}

a:hover > p {
  color: #F84B7F !important;
}

input {
  background: none;
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.81);
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 16px;
  padding: 12px;
  transition-duration: 0.3s;
}
input.ng-invalid.ng-touched {
  border-bottom-color: #DC2137;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.41);
}

input:focus {
  border-color: #F84B7F;
  color: #F84B7F;
  outline: none;
}

textarea {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.41);
  border-radius: 20px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.81);
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 16px;
  padding: 12px 24px;
  transition-duration: 0.3s;
}

textarea::placeholder {
  color: rgba(0, 0, 0, 0.41);
}

textarea:focus {
  border-color: #F84B7F;
  color: #F84B7F;
  outline: none;
}

ul li {
  color: var(--text-light-color);
}

section {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
}

section > div.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row !important;
}

.col {
  display: flex;
  flex-direction: column !important;
}

.m-0 {
  margin: 0;
}

.al-c {
  align-items: center;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */
.scrollbar {
  scrollbar-gutter: stable;
}

/* total width */
.scrollbar::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
  width: 16px;
  height: 16px;
  z-index: 999999;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 16px;
  border: 0px solid var(--background-secondary-color);
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}

/* scrollbar when element is hovered */
.scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: var(--text-disabled-color);
  border: 4px solid var(--background-color);
}

/* scrollbar when scrollbar is hovered */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: var(--text-disabled-color);
  border: 4px solid var(--background-secondary-color);
}

/* The overlay that sits on top of the parent element */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Adjust the background to your preference (solid, semi-transparent, etc.) */
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Sit above other elements in the parent */
}

/* The box-spinner loader */
.loader {
  width: 40px;
  height: 40px;
  margin: auto;
  position: relative;
}

.loader:before {
  content: "";
  width: 40px;
  height: 5px;
  background: #000;
  /* color of the shadow */
  opacity: 0.25;
  position: absolute;
  top: 60px;
  /* adjust to taste */
  left: 0;
  border-radius: 50%;
  animation: shadow 0.5s linear infinite;
}

.loader:after {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--primary-color);
  /* color of the spinning box */
  animation: bxSpin 0.5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
}

/* Box spin animation */
@keyframes bxSpin {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}
/* Shadow pulsing beneath the box */
@keyframes shadow {
  0%, 100% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1);
  }
}
.tooltip-container {
  background-color: #0E1420;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  max-width: 270px;
  opacity: 0;
  padding: 12px 16px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
  z-index: 1000;
}

.tooltip-container.show {
  opacity: 1;
}

/* Tablet styling */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

:root {
  --font-sans: "" ;
}

:root {
  --background: 0 0% 100%;
  --foreground: 224 71.4% 4.1%;
  --muted: 220 14.3% 95.9%;
  --muted-foreground: 220 8.9% 46.1%;
  --popover: 0 0% 100%;
  --popover-foreground: 224 71.4% 4.1%;
  --card: 0 0% 100%;
  --card-foreground: 224 71.4% 4.1%;
  --border: 220 13% 91%;
  --input: 220 13% 91%;
  --primary: 220.9 39.3% 11%;
  --primary-foreground: 210 20% 98%;
  --secondary: 220 14.3% 95.9%;
  --secondary-foreground: 220.9 39.3% 11%;
  --accent: 220 14.3% 95.9%;
  --accent-foreground: 220.9 39.3% 11%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 20% 98%;
  --ring: 224 71.4% 4.1%;
  --radius: 1rem;
}

.dark {
  --background: 224 71.4% 4.1%;
  --foreground: 210 20% 98%;
  --muted: 215 27.9% 16.9%;
  --muted-foreground: 217.9 10.6% 64.9%;
  --popover: 224 71.4% 4.1%;
  --popover-foreground: 210 20% 98%;
  --card: 224 71.4% 4.1%;
  --card-foreground: 210 20% 98%;
  --border: 215 27.9% 16.9%;
  --input: 215 27.9% 16.9%;
  --primary: 210 20% 98%;
  --primary-foreground: 220.9 39.3% 11%;
  --secondary: 215 27.9% 16.9%;
  --secondary-foreground: 210 20% 98%;
  --accent: 215 27.9% 16.9%;
  --accent-foreground: 210 20% 98%;
  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 210 20% 98%;
  --ring: 216 12.2% 83.9%;
}